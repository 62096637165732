import { Card, CardActionArea, CardContent, Typography } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { useStyles } from '../contexts/StylesProvider';

const MenuCard = ({
    children,
    buttonLabel,
    disabled,
    navLink,
    onClick,
    sxOverride ,
    }) => {

    //presence of onClick property supersedes navLink property

    const styles = useStyles();

    return (
        <Card sx={{...styles.cardRootMenuCard, ...sxOverride}} elevation={3}>
            <CardActionArea
                component = {navLink ? NavLink : undefined}
                to = {(navLink && onClick===undefined) ? navLink : undefined}
                onClick = {onClick? onClick : undefined}
                disabled = {disabled}
            >
                <CardContent sx={styles.cardContentMenuCard}>
                    {children}
                    {buttonLabel && <Typography
                        color= {disabled ? 'grey' : 'primary.main'}
                    >{buttonLabel}</Typography>}
                </CardContent>
            </CardActionArea>
        </Card>
    )
}

export default MenuCard;