import { Container } from "@mui/material"
import FlashMessage from "./FlashMessage"
const Body = ({ children }) => {
    return (
        <Container id="bodyContainer" sx={{ marginTop: 2 }}>
            <FlashMessage />
            {children}
        </Container>
    )
}

export default Body