export function compareValues(key, order='asc') {
    //comparison function used for sorting a list of objects by one of the elements inside
    //e.g. objectsList.sort(compareValues(element,'desc'))
    
    return function(a,b) {
        if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
            return 0;
        }

        const varA = (typeof a[key] === 'string') ? a[key].toUpperCase() : a[key];
        const varB = (typeof b[key] === 'string') ? b[key].toUpperCase() : b[key];

        let comparison=0;
        if (varA > varB) {
            comparison = 1;
        } else if (varA < varB) {
            comparison = -1;
        };
        if (order === 'desc') {
            comparison =  comparison * -1;
        };
        return comparison;
    };
};

export function multiSort(data, definition) {
    //validate parameters
    if(!Array.isArray(data)) {
        return {
            status: -1,
            description: 'Data parameter was not an array',
            result: data,
        };
    };
    if(!Array.isArray(definition)) {
        return {
            status: -2,
            description: 'Definition parameter was not an array',
            result: data,
        };
    };

    //sort definition elements
    definition.sort(compareValues('sequence'));

    //sort function factory
    const makeSortFunction = () => {
        return function(a,b) {
            let returnVal = 0;

            for (let definitionItem of definition) {
                const descFactor = definitionItem.sortOrder === 'asc' ? 1 : -1

                const varA = (typeof a[definitionItem.dataElement] === 'string'
                    ? a[definitionItem.dataElement].toUpperCase()
                    : a[definitionItem.dataElement]
                );

                const varB = (typeof b[definitionItem.dataElement] === 'string'
                    ? b[definitionItem.dataElement].toUpperCase()
                    : b[definitionItem.dataElement]
                );

                if (varA > varB) {
                    returnVal = (1 * descFactor);
                }
                else if (varA < varB) {
                    returnVal = (-1 * descFactor);
                }
                if (returnVal !==0) {
                    break;
                }
            };
            return returnVal;
        };
    };

    //do the sort
    let sorted = [...data]
    sorted = data.sort((a,b) => makeSortFunction()(a,b));
    return { 
        status: 0,
        result: sorted,
    };
};

export function initCap(theString) {
    //Returns the input string with the first character in upper case
    return theString[0].toUpperCase() + theString.substring(1)
}

export function buildPersonLabel(person) {
    //requires an object with at least isCamper, fname and lname elements
    return ((person.is_camper ? 'C - ' : 'S - ') + person.first_name + ' ' + person.last_name)
};

export function makeSortFunction(definition) {
    return ((a,b) => {
        definition.map((definitionItem) => {
            const descFactor = definitionItem.sortOrder === 'asc' ? 1 : -1
            if (a[definitionItem.dataElement] < b[definitionItem.dataElement]) return (1 * descFactor);
            if (a[definitionItem.dataElement] > b[definitionItem.dataElement]) return (-1 * descFactor);
            return undefined;
        })
        return 0;
    })
};

export function earlierDate(date1,date2) {
    /*
        returns which date is earlier: 'date1', 'date2', or 'equal' if equal
    */
    const d1 = new Date(date1).getTime();
    const d2 = new Date(date2).getTime();

    if (d1 < d2) return 'date1';
    if (d2 < d1) return 'date2';
    return 'equal';
        
};

export function updateObjectInState(currentObject,updateObject,setterFunction) {
    const newObj = {...currentObject, ...updateObject};
    setterFunction(newObj);
    return 0;
};

export function replaceStateArrayElementById(currentArray,targetId,newElement,setterFunction) {
    //if the currentArray is empty, just store the new element as the only item in the array
    if (!currentArray) {
        setterFunction([newElement])
        return 1;
    };
    const arrIndex = currentArray.findIndex((element) => parseInt(element.id)===parseInt(targetId));
    let newArray = [...currentArray]

    //if newElement is null, remove the old element
    if (newElement==null) {
        newArray.splice(arrIndex,1);
    }
    //else replace the old with the new
    else {
        newArray.splice(arrIndex,1,newElement)
    };
    setterFunction(newArray);
    return 0;
};

export async function replaceStateArrayElementByIdAsync(currentArray,targetId,newElement,setterFunction) {
    replaceStateArrayElementById(currentArray,targetId,newElement,setterFunction)
};

export function inArray(lookFor, lookIn, matchAll = false) {
    if (matchAll) {
        return lookFor.every(i => lookIn.includes(i));
    }
    else {
        return lookFor.some(i => lookIn.includes(i));
    };
};

export function handleStandardAPIResponse(resultObj, notOKText, flashFunction, flashType, setterFunction, notOKValue=null) {
    if (resultObj.ok) {
        if (setterFunction) setterFunction(resultObj.body);
    }
    else {
        if (resultObj.status !== 404) {
            flashFunction(`${notOKText} (${resultObj.status})`,flashType);
        };
        if (setterFunction) setterFunction(notOKValue);
    };
};

export function isEmpty(obj) {
    for (const prop in obj) {
      if (Object.hasOwn(obj, prop)) {
        return false;
      };
    };
  
    return true;
};

export function buildProgramParticipantsChoices(programParticipants) {
    const unsorted = programParticipants.map((p) => {
        return {
            id: p.person.id,
            label: `${p.program_role.abbreviation} - ${p.person.full_name}`,
        }
    });
    const sorted = multiSort(
        unsorted,
        [{
            sequence: 1,
            dataElement: 'label',
            sortOrder: 'asc',
        }]            
    );
    if (sorted.status === 0) {
        return sorted.result
    }
    else {
        return unsorted;
    };
};
        
export function dateFormat(value, include_time=true) {
    if (value===null) return null
    if (include_time) {
        return new Date(value+'Z').toLocaleString('en-US', {timeZone: process.env.REACT_APP_ORG_TIMEZONE});
    }
    else {
        return new Date(value+'Z').toLocaleString('en-US', {timeZone: process.env.REACT_APP_ORG_TIMEZONE, year: '2-digit', month: '2-digit', day: '2-digit'});
    };
};
