import { Fragment, useEffect } from 'react';
import { Settings as SettingsIcon,
    SportsKabaddi as ActivitiesIcon,
    EditLocation as LocationsIcon,
    EmojiPeople as ParticipantsIcon,
    Event as ProgramsIcon,
    People as UsersIcon,
    PermContactCalendar as ImageOfTheDayIcon,
    Checklist as RolesIcon,
    } from '@mui/icons-material';

import MenuCard from "../components/MenuCard";
import Body from "../components/Body"
import { useEnsureTab } from "../contexts/TabsProvider";
import { useUser } from '../contexts/UserProvider';
import { useStyles } from '../contexts/StylesProvider';
import { useImagesGroup } from '../contexts/ImageGroupProvider';

const SettingsPage = () => {

    const { user } = useUser();
    const styles = useStyles();
    const ensureTab = useEnsureTab();
    const { resetContext } = useImagesGroup();

    useEffect(() => {
        ensureTab('/settings');
    },[ensureTab]);

    useEffect(() => {
        resetContext();
    },[resetContext]);

    return (
        <Body>
            {(user.rights.includes('ref_data') || user.rights.includes('user_mgt')) &&
                <Fragment>
                    {user.rights.includes('ref_data') &&
                        <MenuCard buttonLabel={'Manage Activities'} navLink={'/activities'}>
                            <ActivitiesIcon sx={styles.iconMenuCard} />
                        </MenuCard>
                    }
                    <MenuCard buttonLabel={'Manage Image of the Day'} navLink={'/imageoftheday'}>
                        <ImageOfTheDayIcon sx={styles.iconMenuCard} />
                    </MenuCard>
                    {user.rights.includes('ref_data') &&
                        <Fragment>
                            <MenuCard buttonLabel={'Manage Locations'} navLink={'/locations'}>
                                <LocationsIcon sx={styles.iconMenuCard} />
                            </MenuCard>
                            <MenuCard buttonLabel={'Manage People'} navLink={'/people'}>
                                <ParticipantsIcon sx={styles.iconMenuCard} />
                            </MenuCard>
                            <MenuCard buttonLabel={'Manage Programs'} navLink={'/programs'}>
                                <ProgramsIcon sx={styles.iconMenuCard} />
                            </MenuCard>
                        </Fragment>
                    }
                    {user.rights.includes('user_mgt') &&
                        <Fragment>
                            <MenuCard buttonLabel={'Manage Roles'} navLink={'/roles'}>
                                <RolesIcon sx={styles.iconMenuCard} />
                            </MenuCard>
                            <MenuCard buttonLabel={'Manage Users'} navLink={'/users'}>
                                <UsersIcon sx={styles.iconMenuCard} />
                            </MenuCard>
                        </Fragment>
                    }
                </Fragment>
            }
            <MenuCard buttonLabel={'Change Your Password'} navLink={'/password'}>
                <SettingsIcon sx={styles.iconMenuCard} />
            </MenuCard>
        </Body>
    );
}

export default SettingsPage