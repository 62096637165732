import { AppBar, Button, Tab, Tabs, Toolbar, Typography } from "@mui/material";
import { Fragment, useContext } from "react";
import { NavLink } from "react-router-dom";

import { useUser } from "../contexts/UserProvider";
import { TabsContext } from "../contexts/TabsProvider";
import { inArray } from "../utils/utils";

const Header = () => {

    const { currentTab, setCurrentTab } = useContext(TabsContext);

    const { user, logout } = useUser();

    document.title = process.env.REACT_APP_NAME

    return (
        <Fragment>
            <AppBar position="static">
                <Toolbar>
                    <img id="org_logo" src="/static/org-logo.png" alt="organization logo" style={{ padding: 7, width: 80 }}/>
                    <Typography variant="h6" style={{ flexGrow: 1, paddingLeft: 10 }} sx={{ fontWeight: 'bold' }}>
                        {process.env.REACT_APP_NAME}
                    </Typography>
                    {user !== null && <Button onClick={logout} style={{ color: 'white' }}><Typography variant='caption' sx={{ fontWeight: 'bold' }}>Log out</Typography></Button>}
                </Toolbar>
            </AppBar>
            {user && 
                <Tabs
                    centered
                    indicatorColor="primary"
                    textColor="primary"
                    value={currentTab}
                    onChange={(event,index) => setCurrentTab(index)}
                >
                    <Tab label="Dashboard" value="/dashboard" component={NavLink} to="/dashboard" />
                    {user.rights.includes('view') && <Tab label="Search" value="/search" component={NavLink} to="/search" />}
                    {user.rights.includes('upload') && <Tab label="Import" value="/import" component={NavLink} to="/import" />}
                    {inArray(['view','edit'],user.rights) && <Tab label="Images" value="/images" component={NavLink} to="/images" />}
                    {user.rights.includes('view') && <Tab label="Collections" value="/collections" component={NavLink} to="/collections"/>}
                    <Tab label="Settings" value="/settings" component={NavLink} to="/settings" />
                </Tabs>    
            }
        </Fragment>
    );
}

export default Header;